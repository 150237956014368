//REACTJS
import React, { useEffect, useState } from 'react';
//GATSBYJS
import { navigate, Link } from 'gatsby';
//FORMIK & YUP
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//AWS-AMPLIFY
import { Auth, Hub } from 'aws-amplify';
//APPSYNC-QUERIES
import { fetchGetOneUser } from '../appsync_functions/queries';
//CONTEXT-API
import { useStateValue } from '../context/StateContext';
import { settingISSIGNEDIN, settingUSERNAME, settingDISPLAY_USERNAME_PAGE, settingROLE } from '../context/contextapi';

// Icons
import { LuMailOpen } from "react-icons/lu";
import { PiLockLight } from "react-icons/pi";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import eye icons from react-icons

// Toast
import { Toaster, toast } from 'react-hot-toast';

// Helmet 
import Helmet from "react-helmet";
import { RiFacebookFill } from 'react-icons/ri';

export default function Login() {
    const [{ isSignedin }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser().then((userData) => {
                        setUser(userData);
                        console.log('Signed In:', userData);
                    });
                    break;
                case 'signOut':
                    setUser(null);
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure', data);
                    break;
                default:
            }
        });

        getUser().then((userData) => {
            setUser(userData);
            console.log('Signed In:', userData);
        });
    }, []);


    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then((userData) => userData)
            .catch(() => console.log('Not signed in'));
    }



    const initialValues = {
        email: "",
        password: ""
    }


    const validationSchema = Yup.object().shape({
        email: Yup
            .string()
            .email('Must be a valid email')
            .required('Email is required'),
        password: Yup
            .string()
            .min(6, 'Must be at least 6 characters')
            .matches(/[a-z]/, 'Must have at least 1 lowercase letter')
            .matches(/[A-Z]/, 'Must have at least 1 uppercase letter')
            .matches(/[0-9]/, 'Must have at least 1 number')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Must have at least 1 symbol')
            .required('Password is required')
    });


    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        setLoading(true)
        setTimeout(async () => {
            try {
                /* using here due to ContextApi disptach accessable only in react components */
                await Auth.signIn(
                    values.email,
                    values.password
                );
                console.log('successfully login');

                dispatch(settingISSIGNEDIN(true));
                toast.success('Logged In successfully', {
                    duration: 4000,
                    position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                    style: {
                        backgroundColor: '#d9d9d9',
                        padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                });
                navigate("/");

                try {
                    const userInfo = await Auth.currentUserInfo();
                    console.log('successfully username:', userInfo.username);

                    //fetching data from APPSYNC
                    const fetchFunction = async () => await fetchGetOneUser(userInfo.username);
                    const { username, role } = await fetchFunction();
                    //fetching data from APPSYNC

                    dispatch(settingUSERNAME(username));
                    dispatch(settingROLE(role));
                }
                catch (error) {
                    console.log('error username:', error);
                    toast.error(error.message.toString(), {
                        duration: 4000,
                        position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                        style: {
                            backgroundColor: '#d9d9d9',
                            padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                            fontSize: '14px',
                            fontWeight: 'bold'
                        },
                    });
                }
            }
            catch (error) {
                console.log('error login', error);
                toast.error(error.message.toString(), {
                    duration: 4000,
                    position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                    style: {
                        backgroundColor: '#d9d9d9',
                        padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                });
            }
            finally {
                setLoading(false)
            }

            setSubmitting(false);
        }, 1500);
    }



    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);


    if (user) return <></>;


    return (
        <div className='flex justify-center font-poppins'>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Helmet>
            <Toaster />
            <div className='w-full md:w-[600px] min-h-[100dvh] flex flex-col px-5 pt-10'>
                <div>
                    <div className='flex justify-end'>
                        <Link to="/signup" className='text-primary text-sm text-right'>Create account</Link>
                    </div>
                    <div className='mt-7'>
                        <h2 className='text-lg text-zinc-800 font-semibold'>Welcome Back!</h2>
                        <p className='text-sm text-zinc-600'>Register the email address to continue</p>
                    </div>
                    <div className='mt-5 flex gap-3'>
                        <button
                            onClick={async () => {
                                try {
                                    await Auth.federatedSignIn({ provider: 'Google' });
                                    dispatch(settingDISPLAY_USERNAME_PAGE(true));
                                }
                                catch (error) {
                                    console.log('error signing in', error);
                                }
                            }}
                            className='w-[40px] h-[40px] border-[0.9px] border-zinc-300 rounded-lg flex justify-center items-center'
                        >
                            <FcGoogle className='text-lg' />
                        </button>
                        <button
                            onClick={async () => {
                                try {
                                    await Auth.federatedSignIn({ provider: 'Facebook' });
                                    dispatch(settingDISPLAY_USERNAME_PAGE(true));
                                }
                                catch (error) {
                                    console.log('error signing in', error);
                                }
                            }}
                            className='w-[40px] h-[40px] border-[0.9px] border-zinc-300 rounded-lg flex justify-center items-center'
                        >
                            <RiFacebookFill className='text-lg text-blue-600' />
                        </button>
                    </div>
                    <div className='flex justify-between items-center py-4'>
                        <span className='w-[45%] border-t-[0.9px] border-zinc-300'></span>
                        <span className='text-sm'>or</span>
                        <span className='w-[45%] border-t-[0.9px] border-zinc-300'></span>
                    </div>
                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form className="rounded-md flex-grow flex flex-col gap-5">
                            <div className="flex flex-col gap-2">
                                <label htmlFor="email" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Email Address
                                </label>
                                <div className='rounded-lg border-[0.9px] border-zinc-300 py-3 px-4 flex items-center gap-3'>
                                    <LuMailOpen className='text-zinc-400' />
                                    <Field
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="w-full text-sm focus:outline-none placeholder:text-xs"
                                        placeholder='e. g. "example@mail.com"'
                                    />
                                </div>
                                <ErrorMessage name="email" component="div" className="text-red-500 text-xs" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="password" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Password
                                </label>
                                <div className='rounded-lg border-[0.9px] border-zinc-300 py-3 px-4 flex items-center gap-3'>
                                    <Field
                                        type={showPassword ? "text" : "password"} // Toggle between "text" and "password"
                                        name="password"
                                        id="password"
                                        className="w-full text-sm focus:outline-none placeholder:text-xs"
                                        placeholder="Enter the password"
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="focus:outline-none text-zinc-400"
                                    >
                                        {showPassword ? <FiEyeOff /> : <FiEye />} {/* Show appropriate icon */}
                                    </button>
                                </div>
                                <ErrorMessage name="password" component="div" className="text-red-500 text-xs" />
                            </div>
                            <div className='mt-auto flex justify-center pt-10'>
                                <button
                                    type="submit"
                                    className="bg-primary text-white font-medium p-2 pl-4 flex justify-between items-center rounded-full w-[150px] text-center text-sm"
                                    disabled={isSubmitting}
                                >
                                    {
                                        loading ? 'loading ...' :
                                            'Sign In'
                                    }
                                    <div className='w-[30px] h-[30px] flex justify-center items-center rounded-full bg-[#556FFF]'>
                                        <MdOutlineKeyboardArrowRight className='font-bold' />
                                    </div>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className='border-t-[0.9px] border-zinc-300 py-10 mt-10'>
                    <p className='text-sm text-center text-zinc-600'>Need help? <span className='text-primary'>Contact our support team</span></p>
                </div>
            </div>
        </div>
    )
}


export function Head() {
    return (
        <title> Login | MassageOpen </title>
    )
}